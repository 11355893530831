@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.font-roboto-condenced {
  font-family: "Roboto Condensed", sans-serif;
}

@font-face {
  font-family: "Cocogoose-Compressed-Bold";
  src: local("Cocogoose-Compressed-Bold"),
    url("../assets/fonts/Cocogoose-Compressed-Bold.ttf") format("truetype");
}

/*    New Font Family   */
@font-face {
  font-family: "Founder Grotesk Condenced";
  src: local("founders-grotesk-condensed-regular"),
    url("../assets/fonts/founders-grotesk-condensed-regular.woff2")
      format("opentype");
}

@font-face {
  font-family: "Founder Grotesk Condenced Light";
  src: local("founders-grotesk-condensed-light"),
    url("../assets/fonts/founders-grotesk-condensed-light.woff2")
      format("opentype");
}

@font-face {
  font-family: "Founder Grotesk Condenced Medium";
  src: local("founders-grotesk-condensed-medium"),
    url("../assets/fonts/founders-grotesk-condensed-medium.woff2")
      format("opentype");
}

@font-face {
  font-family: "Founder Grotesk Condenced Semibold";
  src: local("founders-grotesk-condensed-semibold"),
    url("../assets/fonts/founders-grotesk-condensed-semibold.woff2")
      format("opentype");
}

@font-face {
  font-family: "Founder Grotesk Condenced Bold";
  src: local("founders-grotesk-condensed-bold"),
    url("../assets/fonts/founders-grotesk-condensed-bold.woff2")
      format("opentype");
}

@font-face {
  font-family: "Drunk Cry Heavy";
  src: local("DrukCyr-Heavy"),
    url("../assets/fonts/DrukCyr-Heavy.woff") format("opentype");
}

@font-face {
  font-family: "FoundersGrotesk-Regular";
  src: local("FoundersGrotesk-Regular"),
    url("../assets/fonts/FoundersGrotesk-Regular.ttf") format("opentype");
}

.font-gt {
  font-family: "FoundersGrotesk-Regular";
}

.font-cocogose-cb {
  font-family: "Cocogoose-Compressed-Bold";
}

.font-founder-gt-cn-b {
  font-family: "Founder Grotesk Condenced Bold";
}

.font-founder-gt-cn {
  font-family: "Founder Grotesk Condenced";
}

.font-founder-gt {
  font-family: "FoundersGrotesk-Regular";
}

/*    New Fonts End */

/*@font-face {*/
/*  font-family: "Messina Bold";*/
/*  src: local("MessinaSansMono-Bold"),*/
/*    url("../assets/MessinaSansMono-Bold.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*  font-family: "Messina";*/
/*  src: local("MessinaSansMono"),*/
/*    url("../assets/MessinaSansMono-Regular.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*  font-family: "NeueHassDisplayBoldItalic";*/
/*  src: local("NeueHassDisplayBoldItalic"),*/
/*    url("../assets/NeueHaasDisplay-BoldItalic.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*  font-family: "NeueHassDisplayBlackItalic";*/
/*  src: local("NeueHassDisplayBlackItalic"),*/
/*    url("../assets/NeueHaasDisplay-BlackItalic.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*  font-family: "PPFormula";*/
/*  src: local("PPFormula"),*/
/*    url("../assets/PPFormula-CondensedBlack.ttf") format("truetype");*/
/*}*/

html {
  font-family: "Founder Grotesk Condenced";
  scroll-behavior: smooth;
}

.f-me {
  font-family: "Founder Grotesk Condenced";
}

.f-me-bold {
  font-family: "Founder Grotesk Condenced Bold";
}

.f-nh-black {
  font-family: "Founder Grotesk Condenced Bold";
}

.f-nh-bold {
  font-family: "Founder Grotesk Condenced Bold";
}

.f-pp {
  font-family: "Drunk Cry Heavy";
}

.navbar-logo path:nth-child(-n + 10) {
  opacity: 0;
}

.navbar-logo path:nth-child(10) {
  animation: 2s reveal-1 0.25s forwards infinite;
}
.navbar-logo path:nth-child(9) {
  animation: 2s reveal-2 0.25s forwards infinite;
}
.navbar-logo path:nth-child(8) {
  animation: 2s reveal-3 0.25s forwards infinite;
}
.navbar-logo path:nth-child(7) {
  animation: 2s reveal-4 0.25s forwards infinite;
}
.navbar-logo path:nth-child(6) {
  animation: 2s reveal-5 0.25s forwards infinite;
}
.navbar-logo path:nth-child(5) {
  animation: 2s reveal-6 0.25s forwards infinite;
}
.navbar-logo path:nth-child(4) {
  animation: 2s reveal-7 0.25s forwards infinite;
}
.navbar-logo path:nth-child(3) {
  animation: 2s reveal-8 0.25s forwards infinite;
}
.navbar-logo path:nth-child(2) {
  animation: 2s reveal-9 0.25s forwards infinite;
}
.navbar-logo path:nth-child(1) {
  animation: 2s reveal-10 0.25s forwards infinite;
}

@keyframes reveal-1 {
  0% {
    opacity: 0;
  }
  6.25% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes reveal-2 {
  0% {
    opacity: 0;
  }
  6.25% {
    opacity: 0;
  }
  12.5% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-3 {
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 0;
  }
  18.75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-4 {
  0% {
    opacity: 0;
  }
  18.75% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-5 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  31.25% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-6 {
  0% {
    opacity: 0;
  }
  31.25% {
    opacity: 0;
  }
  37.5% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-7 {
  0% {
    opacity: 0;
  }
  37.5% {
    opacity: 0;
  }
  43.75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-8 {
  0% {
    opacity: 0;
  }
  43.75% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-9 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  56.25% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reveal-10 {
  0% {
    opacity: 0;
  }
  56.25% {
    opacity: 0;
  }
  62.5% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hover-btn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 200px;
  background: linear-gradient(
    to right,
    transparent 10%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent
  );
  transform: translate(-50%, -50%);
  left: -150%;
  transition: 1.5s;
}

.hover-btn:hover:before {
  left: 150%;
}

@keyframes marquee {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
}

.bg-bt {
  background: url("../assets/bg-bt.svg") no-repeat center/cover;
}

.text-red {
  color: #e75346;
}

.trusted-by-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(248, 244, 230, 1) 50%,
    rgba(248, 244, 230, 1) 100%
  );
}
